import {
  faCartShopping,
  faCopy,
  faRefresh,
  faShareNodes,
} from '@fortawesome/free-solid-svg-icons';
import {oneOfType, object, number, bool, func, string} from 'prop-types';
import React, {useEffect, useState} from 'react';
import toast from 'react-hot-toast';
import {View, Text, TextInput, Image, FlatList} from 'react-native';
import QRCode from 'react-native-qrcode-svg';

import EtherButton from './EtherButton';
import EtherDialog from './EtherDialog';
import FaIcon from './FaIcon';
import {useOperator} from '../context/OperatorContext';
import {useTheme} from '../context/ThemeContext';
import {useAssets} from '../context/assets-context/AssetsContext';
import {STATUS} from '../utils/common/constants';
import {createCart} from '../utils/common/orders';

function copyToClipboard(text) {
  navigator.clipboard.writeText(text).then(
    () => {
      toast.success('Copied to clipboard');
    },
    () => {
      toast.error('Failed to copy cart link');
    },
  );
}

ShareModal.propTypes = {
  show: bool.isRequired,
  onClose: func,
  cartUrl: string,
};
function ShareModal({show, onClose = () => {}, cartUrl}) {
  const {style, values} = useTheme(getThemedStyles);
  const {currentPack, currentEvent, assets} = useAssets();
  const previewThumbs = assets.slice(0, 4);

  const [modalStatus, setModalStatus] = useState(STATUS.IDLE);
  const [printMode, setPrintMode] = useState(false);

  function closeModal() {
    setPrintMode(false);
    setModalStatus(STATUS.IDLE);
    onClose();
  }

  function print() {
    setPrintMode(true);
    toast.dismiss();
    setTimeout(() => {
      window.print();
      return setPrintMode(false);
    }, 200);
  }

  function mailToEmail() {
    window.open(
      `mailto:?subject=Your%20EtherMedia%20cart%20is%20waiting%20for%20you%21%20${currentEvent}%20-%20${currentPack}&body=Your%20EtherMedia%20cart%20is%20waiting%20for%20you%21%0A%0AFollow%20this%20link%20to%20purchase%20and%20download%20your%20media%3A%20%0A${cartUrl}%0A${currentEvent}%20-%20${currentPack}%0A%0AThis%20link%20goes%20to%20your%20checkout%20page.%20Fill%20out%20your%20payment%20information%20to%20complete%20the%20order.%20When%20you%20complete%20the%20order%2C%20you%20can%20download%20your%20purchase%20immediately.`,
    );
  }

  const controls = [
    {
      text: 'Email',
      onPress: () => mailToEmail(),
      variant: 'primary',
    },
    {
      text: 'Print',
      onPress: () => print(),
      variant: 'primary',
    },
  ];

  return (
    <EtherDialog
      style={printMode ? style.printModal : null}
      show={show}
      onClose={closeModal}
      header={
        printMode ? 'Scan the QR code to begin your purchase!' : 'Cart Link'
      }
      controls={printMode ? null : controls}
      transparent={!printMode}
      closeCross={!printMode}
      backdropStyle={printMode ? style.printBackdrop : null}
      status={modalStatus}
    >
      <Text style={style.headerText}>
        {currentEvent?.name} - {currentPack?.name}
      </Text>

      <EtherButton
        basic
        style={style.qrCodeButton}
        onPress={() => window.open(cartUrl)}
      >
        <QRCode
          value={cartUrl}
          quietZone={20}
          size={250}
          color="black"
          backgroundColor={printMode ? 'white' : values.BGFIRST}
        />
        <Text style={style.qrURL}>{cartUrl}</Text>
      </EtherButton>
      {printMode ? null : (
        <>
          <Text style={style.tipText}>
            Adjust print preview options for best results.
          </Text>
          <Text style={style.tipText}>
            Learn to set up your{' '}
            <a
              href="https://www.makeuseof.com/tag/how-to-change-the-default-email-program-for-mailto-links/"
              target="_blank"
              rel="noopener noreferrer"
            >
              email client
            </a>
            .
          </Text>
        </>
      )}
      {printMode ? (
        <>
          <FlatList
            data={previewThumbs}
            numColumns={previewThumbs.length}
            renderItem={({item}) => (
              <Image style={style.image} source={{uri: item.thumb.url}} />
            )}
          />
          <View style={style.printFooter}>
            <Text style={style.printFooterText}>
              Service powered by EtherMedia
            </Text>
          </View>
        </>
      ) : null}
    </EtherDialog>
  );
}

CreateCartButton.propTypes = {
  style: oneOfType([object, number]),
};
export default function CreateCartButton({style: overrides}) {
  const {style, values} = useTheme(getThemedStyles);
  const [cartUrl, setCartUrl] = useState();
  const [showShareModal, setShowShareModal] = useState(false);
  const {getStoreSlug} = useOperator();
  const {currentPack, currentEvent, assets} = useAssets();
  const host = window.location.origin;

  useEffect(() => {
    setCartUrl(null);
  }, [currentPack]);

  async function createLink() {
    const cart = [
      {
        name: currentPack?.name,
        event: currentEvent?.name,
        packId: currentPack?._id,
        assets,
      },
    ];
    const storeSlug = getStoreSlug();
    const {_id: cartId} = await createCart(cart, storeSlug);
    const newCartUrl = `${host}/checkout?cart=${cartId}`;
    setCartUrl(newCartUrl);
    copyToClipboard(newCartUrl);
    toast.success('Cart link created');
  }

  if (cartUrl) {
    return (
      <View
        style={[style.creationMenu, overrides]}
        onPress={() => createLink()}
      >
        <ShareModal
          show={showShareModal}
          onClose={() => setShowShareModal(false)}
          cartUrl={cartUrl}
        />
        <View style={style.menuItem}>
          <TextInput style={style.itemText} value={cartUrl} />
        </View>
        <EtherButton
          basic
          style={style.menuItem}
          onPress={() => copyToClipboard(cartUrl)}
        >
          <FaIcon icon={faCopy} color={values.DARK} size={20} />
        </EtherButton>
        <EtherButton
          basic
          style={style.menuItem}
          onPress={() => setShowShareModal(true)}
        >
          <FaIcon icon={faShareNodes} color={values.DARK} size={20} />
        </EtherButton>
        <EtherButton basic style={style.menuItem} onPress={() => createLink()}>
          <FaIcon icon={faRefresh} color={values.DARK} size={20} />
        </EtherButton>
      </View>
    );
  }

  return (
    <EtherButton
      basic
      style={[style.creationMenu, overrides]}
      onPress={() => createLink()}
    >
      <View style={style.menuItem}>
        <FaIcon icon={faCartShopping} color={values.DARK} size={20} />
        <Text style={style.itemText}>Create Cart Link</Text>
      </View>
    </EtherButton>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  creationMenu: {
    flexDirection: 'row',
    minWidth: 40,
    maxHeight: 40,
    borderRadius: 10,
  },
  emailInput: {
    marginBottom: 20,
    width: 300,
  },
  headerText: {
    fontSize: fontSize.header,
    fontFamily: 'NotoSans_Bold',
    color: theme.DARK,
    marginBottom: 5,
    textAlign: 'center',
    textWrap: 'wrap',
    maxWidth: 300,
  },
  image: {
    height: '1.2in',
    width: '1.2in',
    margin: 10,
    borderRadius: 10,
  },
  itemText: {
    color: theme.DARK,
    fontSize: fontSize.tiny,
    alignSelf: 'center',
    marginLeft: 5,
    letterSpacing: 0,
    fontFamily: 'NotoSans_Bold',
  },
  menuItem: {
    flexDirection: 'row',
    cursor: 'pointer',
    padding: 7,
    borderRadius: 10,
  },
  printBackdrop: {
    backgroundColor: 'white',
  },
  printFooterText: {
    color: theme.DARK,
    fontSize: fontSize.body,
    fontFamily: 'NotoSans_Bold',
  },
  printModal: {
    backgroundColor: 'white',
    height: '9.5in',
    width: '7in',
    justifyContent: 'flex-start',
  },
  qrCodeButton: {
    alignItems: 'center',
    width: 270,
  },
  qrURL: {
    fontSize: fontSize.legal,
    fontFamily: 'NotoSans_Bold',
    color: theme.DARK,
    marginBottom: 10,
    textWrap: 'break-word',
    textAlign: 'center',
  },
  tipText: {
    textWrap: 'wrap',
    fontSize: fontSize.legal,
    fontFamily: 'NotoSans_Bold',
    color: theme.DARK,
    textAlign: 'center',
    maxWidth: 300,
    marginBottom: 10,
  },
});
